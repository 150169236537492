import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/requests";

import "./consulta.css";

const Consulta = () => {
  const [pesquisar, setPesquisar] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    setPesquisar(e.target.value.replace(/\D/g, ""));
    setError(false);
  };

  const handleClick = async () => {
    try {
      const { data } = await api.get(
        `/carteiras/pesquisar?elemento=${pesquisar}`
      );
      navigate(`/carteiras/${data.id}`);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  return (
    <div id="consulta_page" className="consulta_container">
      <img className="consulta_logo" src={"./logo_unisc.svg"} alt="logo" />
      <div className="consulta_title">
        <h3>CONSULTE SUA CARTEIRA</h3>
      </div>
      <div className="consulta_middle">
        <div className="consulta_text">
          <p>
            Preencha o campo abaixo <br></br>
            com <span>CPF</span> ou <span>Código de uso</span>
            contidos na carteira
          </p>
          <input
            className="consulta_input"
            placeholder="CPF / COD. DE USO"
            value={pesquisar}
            onChange={(e) => handleChange(e)}
          />
          {error && <span className="consulta_error"> CARTEIRA NÃO ENCONTRADA! </span>}
          <button className="consulta_button" onClick={() => handleClick()}>
            CONSULTAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default Consulta;